import React, { useState, useEffect } from 'react';
import axios from 'axios'
import './Dashboard.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import EditableFile from '../components/fileEditor';

const fileList = [
    "Admins.cfg",
    "Bans.cfg",
    "ExcludedFactions.cfg",
    "ExcludedFactionSetups.cfg",
    "ExcludedLayers.cfg",
    "LayerRotation.cfg",
    "LayerVoting.cfg",
    "LayerVotingLowPlayers.cfg",
    "LayerVotingNight.cfg",
    "LevelRotation.cfg",
    "MOTD.cfg",
    "Server.cfg",
    "ServerMessages.cfg",
    "VoteConfig.cfg",
    "License.cfg"
];


const Dashboard = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [options, setOptions] = useState(["No Options"]);
    const [selectedOption, setSelectedOption] = useState('Serveur ?');
    const [activeFile, setActiveFile] = useState('Server.cfg');

    useEffect(() => {
        fetchOptions();
    }, []);

    const fetchOptions = async () => {
        try {
            // const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
            const response = await axios.get( `${process.env.REACT_APP_API_URL}/dashboard/servers`, { withCredentials: true } );
            setOptions(response.data); // Assuming the API returns an array of options
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setMenuOpen(false);
        setActiveFile('Server.cfg');
    };

    const handleFileClick = (option) => {
        setActiveFile(option)
    }

    return (<div className="container">
        <div className="leftPanel">
            <div className="header" onClick={toggleMenu}>
                <p>{selectedOption}</p>
                {menuOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
            </div>
            <div className={`dropdownMenu ${menuOpen ? 'open' : ''}`}>
                <ul>
                    {Object.keys(options).map((option, index) => (
                        <li key={index} onClick={() => handleOptionClick(option)}>
                            {option}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="content">
                {fileList.map((file, index) => (
                    <p
                        key={index}
                        className={file === activeFile ? 'selected' : ''}
                        onClick={() => handleFileClick(file)}
                    >
                        {file}
                    </p>
                ))}
            </div>
        </div>
        <div className="rightPanel">
            { selectedOption === 'Serveur ?' ? <h3>Aucun Serveur Selectionné</h3> : <>
                <EditableFile serverName={selectedOption} fileName={activeFile} />
            </>}
        </div>
    </div>);
};

export default Dashboard;