import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './fileEditor.css';

const EditableFile = ({ serverName, fileName }) => {
  const [content, setContent] = useState('');
  const [originalContent, setOriginalContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/files`, {
          params: { serverName, fileName },
          withCredentials: true,
        });
        setContent(response.data);
        setOriginalContent(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFile();
  }, [serverName, fileName]);

  const handleSave = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/files`, {
        serverName: serverName,
        fileName: fileName,
        content: content,
      }, {
        withCredentials: true,
      });
      setOriginalContent(content);
      alert('File saved successfully!');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleCancel = () => {
    setContent(originalContent);
  };

  const handleInput = (e) => {
    const newContent = e.target.value;
    setContent(newContent);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <p htmlFor="fileContent" style={{ margin: '0' }}>Contenu du fichier: {fileName}</p>
      <textarea
        ref={textareaRef}
        value={content}
        onChange={handleInput}
        className="textEditor"
        rows="20"
        cols="80"
      />
      <br />
      <button className="primary-button" onClick={handleSave}>Save</button>
      <button className="secondary-button" onClick={handleCancel}>Cancel</button>
    </div>
  );
};

export default EditableFile;
